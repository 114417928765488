.component {
  & > .title {
    margin-bottom: var(--size-24);
  }
}

.componentGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--size-12);

  @media (--viewport-md) {
    grid-template-columns: repeat(3, 1fr);
  }
}
