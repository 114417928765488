.component {
  padding-top: var(--size-40);
  padding-bottom: var(--size-40);
  background-color: var(--color-orange-100);

  @media (--viewport-md) {
    padding-top: var(--size-80);
    padding-bottom: var(--size-64);
  }
}

.layout {
  display: grid;
  gap: var(--size-18);
  grid-auto-columns: 1fr;
  grid-template-rows: min-content auto min-content;
  grid-template-areas:
    'title'
    'tags'
    'icon';

  @media (--viewport-md) {
    gap: var(--size-30);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'title   x   '
      'tags    tags'
      'icon    icon';
  }

  & > * {
    min-width: 0;
  }

  & > .title {
    grid-area: title;
  }

  & > .tags {
    grid-area: tags;
  }

  & > .icon {
    grid-area: icon;
    justify-self: center;
    max-width: 160px;

    @media (--viewport-md) {
      margin-top: var(--size-42);
    }
  }
}

.title {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-32);
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);
  font-weight: var(--font-weight-base);

  @media (--viewport-md) {
    font-size: var(--font-size-52);
  }
}

.icon {
  color: var(--color-orange-300);
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: var(--size-12) var(--size-6);

  @supports not (gap: var(--size-12) var(--size-6)) {
    & > * {
      margin: var(--size-6) var(--size-3);
    }
  }

  & > .tag {
    height: 50px;
  }
}

.tag {
  display: grid;
  place-content: center;
  padding-right: var(--size-24);
  padding-left: var(--size-24);
  border-radius: 25px;
  font-size: var(--font-size-20);
  color: var(--color-blue-700);
  background-color: var(--color-white);
  transition: color var(--duration-sm) var(--ease-out-quart), background-color var(--duration-sm) var(--ease-out-quart);

  &:focus,
  &:hover {
    color: var(--color-white);
    background-color: var(--color-blue-500);
  }

  & > * {
    min-width: 0;
  }
}
