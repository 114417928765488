.component {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
    align-items: center;
  }
}

.headerContainer {
  display: flex;
  align-items: baseline;
  gap: var(--size-16);
  color: var(--color-blue-700);

  & > .rss {
    margin-top: var(--size-24);
  }
}

.heading {
  font-size: var(--font-size-42);
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-bold);
  line-height: 1;
}

.link {
  display: flex;
  align-items: center;
  color: var(--color-blue-500);
  text-decoration: none;

  & > .icon {
    margin-right: var(--size-12);
  }
}

.rss {
  padding: 2px;
  border-radius: 3px;
  background-color: #fd7700;
  color: var(--color-white);
}
