.component {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > .next {
    flex: 0 0 auto;
    order: 2;
    margin-left: var(--size-10);
  }

  & > .previous {
    order: 1;
    flex: 0 0 auto;
    margin-right: var(--size-10);

    @media (--viewport-sm) {
      order: 0;
    }
  }

  & > .bullets {
    width: 100%;
    margin-bottom: var(--size-20);

    @media (--viewport-sm) {
      width: auto;
      margin-bottom: 0;
    }
  }
}

.next,
.previous {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  overflow: hidden;
  color: var(--color-blue-500);
  padding: var(--size-10) var(--size-18);

  &[aria-hidden='true'] {
    opacity: 0;
  }
}

.bullets {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.hellip {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-10) var(--size-18);
}

.componentBullet {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-10) var(--size-18);
  overflow: hidden;

  &::after {
    content: attr(data-page);
  }

  & > .screenreaderText {
    position: absolute;
    right: 100%;
  }

  & > .indicator {
    position: absolute;
    bottom: 4px;
    width: 20px;
    height: 3px;
  }

  &.isActive {
    color: var(--color-black);
    font-weight: var(--font-weight-semibold);
  }
}

.indicator {
  opacity: 0;

  &.isActive {
    opacity: 1;
  }
}

.componentArrow {
  position: relative;

  &.disabled {
    pointer-events: none;
    color: var(--color-gray-300);
  }

  & > .screenreaderText {
    position: absolute;
    right: 100%;
  }
}
