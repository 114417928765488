.component {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .card {
    margin: var(--size-10);

    @media (--viewport-md) {
      width: 380px;
    }
  }
}

.componentCard {
  display: flex;
  flex-direction: column;
  background-color: var(--color-blue-100);
  padding: var(--size-48) var(--size-32) var(--size-32) var(--size-32);
  align-items: flex-start;

  & > .icon {
    align-self: center;
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-12);
  }
}
