.page {
  padding-bottom: var(--size-80);

  & > .content {
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-bottom: var(--size-48);
    }
  }

  & > .relatedNews {
    margin: var(--size-134) 0 0;
  }
}

.componentRelatedNewsSection {
  position: relative;
  z-index: 0;
  padding: 0;
  background-color: transparent;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 140px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-blue-700);
  }
}

.tagListLayout {
  display: grid;
  gap: var(--size-12) 0;

  & > * {
    min-width: 0;
  }
}
