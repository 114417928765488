.component {
  position: relative;
  padding: var(--size-24) var(--size-32) var(--size-32);
  background-color: var(--color-white);

  & > .title {
    margin-top: var(--size-8);
  }

  & > .relativeToParent {
    position: static;
  }
}

.date {
  color: var(--color-gray-300);
  text-decoration: none;
  display: block;
  font-size: var(--font-size-14);
}

.title {
  display: block;
  text-decoration: none;
  color: unset;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-base);
  line-height: 1;
  padding: 0;
  padding-bottom: 0;
  font-size: var(--font-size-24);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
