.componentContent {
  & > :not(:last-child) {
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-bottom: var(--size-64);
    }
  }
}

.page {
  padding-bottom: var(--size-24);

  @media (--viewport-md) {
    padding-bottom: var(--size-64);
  }

  & > .content {
    margin-top: var(--size-24);

    @media (--viewport-md) {
      margin-top: -125px;
    }
  }
}

.content {
  & > :not(:last-child) {
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-bottom: var(--size-64);
    }
  }
}
