.componentBase {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .content {
    flex-basis: 50%;
  }

  & > .imageContainer {
    flex-basis: 50%;
  }
}

.content {
  padding: var(--size-48);

  & > .title {
    margin-bottom: var(--size-12);
  }
}

.title {
  font-size: var(--font-size-42);
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-700);
  line-height: 1;
}

.subtitle {
  font-size: var(--font-size-24);
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-light);
  color: var(--color-blue-700);
  line-height: 1;
  display: block;
}

.button {
  margin-top: var(--size-24);
}

.imageContainer {
  position: relative;
  padding: var(--size-48) var(--size-48) 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  & > .image {
    max-width: 100%;
  }
}

.imageLeft {
  & > .content {
    order: 2;
  }

  & > .imageContainer {
    order: 1;
  }
}

.centerContent {
  & > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & > .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.greenBg {
  background-color: var(--color-green-100);
}
