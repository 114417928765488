.componentMd,
.componentLg {
  & > .paragraph {
    margin-top: var(--size-10);
  }

  & > .heading + .paragraph {
    margin-top: 1rem;
  }

  & > .heading + .subheading {
    margin-top: 1.5rem;
  }

  & > .subheading + .paragraph {
    margin-top: 1rem;
  }

  & > .heading {
    margin-top: var(--size-32);
  }

  & > .subheading {
    margin-top: var(--size-32);
  }

  & > .bulletList {
    margin: 1.5em 0;
  }

  & > .numberedList {
    margin: 1.5em 0;
  }

  & > .paragraph + .bulletList {
    margin-top: 0.5rem;
  }

  & > .image {
    max-width: 100%;
    height: auto;
    margin-top: var(--size-24);
  }

  & > .ctaButton {
    margin-top: var(--size-10);
  }

  & > .table {
    width: 100%;
    border: none;
    border-collapse: collapse;
    background-color: var(--color-white);

    & .tr .td {
      border-bottom: solid 1px var(--color-blue-100);
      padding: var(--size-8);
    }

    & .tr:first-child {
      color: var(--color-white);
      background-color: var(--color-blue-700);
      border-bottom: none;
    }
  }

  & > .componentQuote {
    margin-top: var(--size-48);
    margin-bottom: var(--size-48);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.componentQuote {
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-700);

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}

.bulletList {
  list-style-type: disc;
  padding-left: 38px;

  & > * {
    padding-left: 10px;

    &::marker {
      font-weight: var(--font-weight-bold);
      color: var(--color-blue-700);
    }
  }
}

.numberedList {
  list-style-type: decimal;
  padding-left: 38px;

  & > * {
    padding-left: 10px;

    &::marker {
      font-weight: var(--font-weight-bold);
      color: var(--color-blue-700);
    }
  }
}

.strong {
  font-weight: var(--font-weight-semibold);
}

.code {
  color: #d12c2a;
}

.componentMd,
.componentMdBoldOnly {
  font-size: var(--font-size-16);
  line-height: 1.5;

  @media (--viewport-md) {
    font-size: var(--font-size-18);
  }
}

.componentLg {
  font-size: var(--font-size-16);
  line-height: 1.5;

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }
}

.heading,
.subheading {
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-base);
  line-height: var(--line-height-heading);
  padding: 0;
}

.heading {
  font-size: var(--font-size-24);

  @media (--viewport-md) {
    font-size: var(--font-size-38);
  }
}

.subheading {
  font-size: var(--font-size-18);

  @media (--viewport-md) {
    font-size: var(--font-size-28);
  }
}
