.component {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--size-22);
  row-gap: var(--size-16);
  scroll-padding-top: var(--size-64);

  @media (--viewport-md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.componentCard {
  background-color: var(--color-blue-100);
  padding: var(--size-24) var(--size-40);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > .link {
    margin-top: var(--size-24);
  }
}

.content {
  & > .title {
    margin: var(--size-12) 0;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: var(--font-size-28);
  font-family: var(--font-family-base);
  color: var(--color-blue-700);
  text-align: center;
}

.link {
  justify-self: flex-end;
}
