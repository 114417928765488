.component,
.componentSingleIcon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--color-blue-500);
}

.component {
  gap: var(--size-12);

  & > .link {
    @supports not (gap: var(--size-12)) {
      margin: var(--size-6);
    }
  }
}

.componentSingleIcon {
  position: relative;

  & > .icon {
    margin-right: var(--size-6);
  }

  & > .tag {
    margin-right: var(--size-6);
  }
}

.tagWithIcon {
  display: flex;
  justify-content: center;

  & > .icon {
    margin-right: var(--size-6);
    height: var(--size-24);
  }
}

.tag {
  &:not(:last-child)::after {
    display: inline;
    content: ',';
  }

  & > .icon {
    margin-right: var(--size-6);
    height: var(--size-24);
  }
}
