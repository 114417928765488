.layout {
  display: flex;
  align-items: flex-start;
  padding: var(--size-32) 0;
  flex-direction: column;

  @media (--viewport-md) {
    padding: var(--size-64) 0;
  }

  & > :first-child {
    @media (--viewport-md) {
      margin-right: var(--size-48);
    }
  }

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .sidebar {
    min-width: 260px;
    max-width: 380px;
  }

  & > .content {
    max-width: 100%;
  }
}

.sidebar {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.content {
  & > .title {
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-bottom: var(--size-42);
    }
  }
}
