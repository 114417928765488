.component {
  background-color: var(--color-blue-100);
  display: flex;
  justify-content: center;
  padding: var(--size-32) 5vw;

  @media (--viewport-md) {
    padding: var(--size-48) calc(2.5vw + var(--size-24));
  }
}

.layout {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & > .title {
    order: 2;

    @media (--viewport-md) {
      order: 1;
    }
  }

  & > .icon {
    width: var(--size-134);
    align-self: center;
    order: 1;

    @media (--viewport-md) {
      width: 350px;
      order: 2;
    }
  }
}

.title {
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-heading);
  line-height: 1;
  color: var(--color-blue-700);
  font-size: var(--font-size-48);

  @media (--viewport-md) {
    font-size: var(--font-size-72);
  }
}
