.component {
  display: flex;
  justify-content: center;
  padding: var(--size-64) var(--container-padding-sides-sm);
  position: relative;
  z-index: 0;
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--size-64) var(--container-padding-sides-md);
  }

  & > .background {
    z-index: -1;
    position: absolute;
    width: 250vw;
    height: 250vw;
    bottom: 0;
  }

  & > .container {
    width: 100%;
  }
}

.background {
  background-color: var(--color-green-100);
  border-radius: 0 0 50% 50%;

  @media (--viewport-md) {
    border-radius: 0;
  }
}

.layout {
  display: flex;
  align-items: center;

  @media (--viewport-md) {
    min-height: 50vh !important;
  }
}

.counter {
  width: 36px !important;
  height: 36px !important;
  font-size: var(--font-size-16);
  font-family: var(--font-family-base);
  background-color: var(--color-blue-700);
  border-radius: 50%;
  display: flex;
  color: var(--color-green-100);
  justify-content: center;
  align-items: center;
  font-weight: var(--font-weight-base-400);

  @media (--viewport-md) {
    width: 60px !important;
    height: 60px !important;
    font-size: var(--font-size-24);
  }
}

.title {
  font-family: var(--font-family-heading);
  color: var(--color-blue-700);
  line-height: var(--line-height-heading);
  font-size: var(--font-size-52);
  position: relative;

  @media (--viewport-md) {
    font-size: var(--font-size-72);
  }

  @media (--viewport-md) {
    font-size: var(--font-size-92);
  }

  & > .counter {
    position: absolute;
    top: -22px;
    right: -40px;

    @media (--viewport-md) {
      top: -36px;
      right: -62px;
    }
  }
}
