.component {
  color: var(--color-blue-700);
  display: grid;
  grid-gap: var(--size-30);
  grid-template-columns: 1fr;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-areas:
      'header  usps'
      'links    links';
  }

  & > .header {
    @media (--viewport-md) {
      grid-area: header;
    }
  }

  & > .links {
    @media (--viewport-md) {
      grid-area: links;
    }
  }

  & > .list {
    @media (--viewport-md) {
      grid-area: usps;
    }
  }
}

.componentList {
  display: grid;
  list-style-type: none;
  padding: 0;
  font-size: var(--font-size-18);
  align-items: center;

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr 1fr;
    grid-gap: var(--size-32) var(--size-6);
  }

  & > .listItem {
    margin-bottom: var(--size-12);
    @media (--viewport-lg) {
      margin-bottom: 0;
    }
  }
}

.listItem {
  display: flex;

  & > .icon {
    margin-right: var(--size-12);
    flex-shrink: 0;
  }
}

.icon {
  color: var(--color-green-500);
}

.links {
  & > * {
    margin-right: var(--size-20);
  }
}
