.component {
  display: flex;
  color: var(--color-blue-500);
  align-items: center;
  text-decoration: none;

  & > .icon {
    margin-right: var(--size-12);
  }
}

.icon {
  display: flex;
  align-items: center;
}
