.component {
  list-style: none;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: var(--size-10);
  }
}

.componentListItem {
  background-color: var(--color-white);
  padding: var(--size-18) 0;
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--color-black);

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .icon {
    width: 70px;
    min-width: 70px;
    margin: 0 var(--size-12);

    @media (--viewport-md) {
      margin: 0 var(--size-24);
      width: 90px;
      min-width: 90px;
    }
  }

  & > .relativeToParent {
    position: static;
  }
}

.icon {
  display: flex;
  align-items: flex-start;

  & > svg {
    height: auto;
  }
}

.body {
  padding: 0 var(--size-18);
  position: relative;

  @media (--viewport-md) {
    padding-left: 0;
  }

  & > .tags {
    margin-bottom: var(--size-8);
  }

  & > .title {
    margin-bottom: var(--size-8);

    @media (--viewport-md) {
      margin-bottom: var(--size-24);
    }
  }

  & > .relativeToParent {
    position: static;
  }
}

.title {
  font-family: var(--font-family-base);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-text);
  color: var(--color-black);
  text-decoration: none;
  display: block;
  position: relative;

  @media (--viewport-md) {
    font-size: var(--font-size-20);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.tags {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;

  & > .tag {
    margin-bottom: var(--size-6);
  }
}

.tag {
  color: var(--color-blue-500);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-14);

  &:not(:last-child) {
    &::after {
      content: '\2022';
      margin: 0 var(--size-6);
    }
  }
}

.componentMetaItems {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: var(--font-size-14);

  &:not(:last-child) {
    margin-bottom: var(--size-14);
  }
}

.metaItem {
  & > .metaItemKey {
    margin-right: var(--size-6);
  }
}
