.componentBase {
  color: inherit;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.linkForward {
  color: var(--color-blue-500);

  & > .icon:not(:last-child) {
    margin-right: var(--size-6);
  }
}

.icon {
  display: flex;
  align-items: center;
}

.componentBlueBase {
  color: var(--color-blue-500);
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.componentIconWithCurrentColor {
  color: currentColor;
}
