.page {
  & > .newsList {
    margin-top: var(--size-40);
    margin-bottom: var(--size-64);

    @media (--viewport-md) {
      margin-top: calc(var(--size-80) * -1);
    }
  }

  & > .pagination {
    margin-bottom: var(--size-64);
  }
}
