.page {
  & > .cta {
    margin-top: var(--size-134);
  }

  & > .hero {
    margin-bottom: var(--size-48);
  }
}

.content {
  padding-bottom: var(--size-24);

  @media (--viewport-md) {
    padding-bottom: var(--size-48);
  }
}

.cta {
  position: relative;
  z-index: 0;
  background-color: transparent;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 140px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-blue-700);
  }
}
