.component {
  background-color: var(--color-blue-100);
  padding: var(--size-40);

  & > .heading:not(:first-child) {
    margin-top: var(--size-12);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-6);
  }
}

.heading {
  font-size: var(--font-size-18);
  color: var(--color-black);
  font-weight: var(--font-weight-bold);
}

.link {
  text-decoration: none;
  color: var(--color-black);
  font-size: var(--font-size-14);
}

.anchor {
  display: block;
}
