.component {
  background-color: var(--color-blue-300);
  display: flex;
  justify-content: center;
  padding: var(--size-64) var(--container-padding-sides-sm) 0;

  @media (--viewport-md) {
    padding: var(--size-64) var(--container-padding-sides-md) 0;
  }

  & > .container {
    width: 100%;
    max-width: var(--size-container-max-width-lg);
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .content {
    @media (--viewport-md) {
      width: 50%;
    }
  }

  & > .imageContainer {
    @media (--viewport-md) {
      margin-left: var(--size-12);
    }
  }
}

.content {
  padding-bottom: var(--size-48);

  @media (--viewport-md) {
    padding-bottom: 173px;
  }

  & > .title {
    margin-bottom: var(--size-12);
  }
}

.imageContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (--viewport-md) {
    justify-content: flex-end;
  }

  & > * {
    max-width: 100%;
    height: auto;
  }
}

.title {
  font-size: var(--font-size-52);
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-700);
  line-height: 1;

  @media (--viewport-md) {
    font-size: var(--font-size-72);
  }
}

.subtitle {
  font-size: var(--font-size-22);
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-light);
  color: var(--color-blue-700);
  line-height: 1;

  @media (--viewport-md) {
    font-size: var(--font-size-28);
  }
}
