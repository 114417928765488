.component {
  display: flex;
  justify-content: center;
  padding: var(--size-64) var(--container-padding-sides-sm);
  position: relative;
  z-index: 0;
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--size-64) var(--container-padding-sides-md);
  }

  & > .background {
    z-index: -1;
    position: absolute;
    width: 250vw;
    height: 250vw;
    bottom: 0;
  }

  & > .container {
    width: 100%;
  }
}

.background {
  background-color: var(--color-green-100);
  border-radius: 0 0 50% 50%;

  @media (--viewport-md) {
    border-radius: 0;
  }
}

.layout {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    min-height: 50vh !important;
    display: grid;
    grid-template-columns: 5fr 3fr;
  }

  & > .content {
    @media (--viewport-md) {
      align-self: center;
    }
  }
}

.content {
  & > .title {
    margin-bottom: var(--size-24);
  }
}

.title {
  display: block;
  font-family: var(--font-family-heading);
  color: var(--color-blue-700);
  line-height: var(--line-height-heading);
  font-size: var(--font-size-52);

  @media (--viewport-md) {
    font-size: var(--font-size-72);
  }

  @media (--viewport-md) {
    font-size: var(--font-size-92);
  }
}

.intro {
  display: block;
  color: var(--color-blue-700);
  font-size: var(--font-size-32);
  line-height: var(--line-height-32);
}

.rssInfo {
  display: flex;
  gap: 5px;
  font-size: var(--font-size-18);
  color: var(--color-blue-700);
  line-height: 1;

  & a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (--viewport-md) {
    justify-content: flex-end;
  }

  & > * {
    max-width: 100%;
    height: auto;
  }
}
