.component {
  align-items: center;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  font-size: var(--font-size-14);
  padding: var(--size-48) 0;
  text-align: center;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .rss {
    margin-top: var(--size-24);
  }
}

.bottom {
  & > .nav {
    margin-top: var(--size-16);
  }
}

.nav {
  @media (--viewport-md) {
    display: inline-block;
  }

  & > * {
    margin: 0 var(--size-6);

    @media (--viewport-md) {
      margin-right: var(--size-24);
    }
  }
}
