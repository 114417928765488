.component {
  & > .backLink {
    margin: var(--size-48) 0 var(--size-64);
  }

  & > .meta {
    margin: var(--size-24) 0 var(--size-48);
  }

  & > .relatedNews {
    margin: var(--size-134) 0 0;
  }
}
