.component_root {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
}

.siteHeader {
  color: var(--color-white);
  background-color: var(--color-blue-700);
}

.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--size-header-height-sm) !important;

  @media (--viewport-md) {
    height: var(--size-header-height-lg) !important;
  }

  & > .navigation {
    display: flex;
  }
}

.componentMenuLarge {
  font-size: var(--font-size-14);
  align-items: center;
  display: none;

  @media (--viewport-lg) {
    display: flex;
  }
}

.componentLogo {
  max-width: 80%;
  color: var(--color-white);

  @media (--viewport-md) {
    max-width: 100%;
  }
}

.componentMenuMobile {
  display: flex;

  @media (--viewport-lg) {
    display: none;
  }
}

.componentDesktopNavList {
  display: flex;
  list-style: none;
  padding-right: var(--size-30);

  & > :not(:last-child) {
    margin-right: var(--size-30);
  }
}

.componentDesktopNavItem {
  display: flex;
  align-items: center;
  position: relative;

  & > .hoverMenu {
    width: 210px;
    position: absolute;
    top: var(--size-24);
    display: none;
  }

  &:hover {
    & > .hoverMenu {
      display: block;
    }
  }
}

.navLink {
  color: var(--color-white);
  font-size: var(--font-size-14);
  text-decoration: none;
}

.desktopNavItemSubitemLink {
  display: flex;
  align-items: self-end;
  position: relative;
  overflow: hidden;
  transition: transform var(--duration-sm) ease;
  transform: translateX(calc(var(--size-24) * -1));
  color: var(--color-white);
  font-size: var(--font-size-14);
  text-decoration: none;

  & > :first-child {
    margin-right: var(--size-6);
  }

  &:hover {
    transform: translateX(0);

    & > .hoverIcon {
      transform: translateX(0);
    }
  }
}

.hoverIcon {
  display: flex;
  align-items: center;
  transform: translateX(calc(var(--size-24) * -1));
  transition: transform var(--duration-sm) ease;
}

.hoverMenu {
  background: var(--color-blue-700);
  padding: var(--size-18);
  padding-top: var(--size-30);
  list-style: none;

  & > :not(:last-child) {
    margin-bottom: var(--size-12);
  }
}

._rootdrawerInner {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  background: var(--color-blue-700);
  overflow-x: hidden;

  @media (--viewport-md) {
    max-width: 500px;
  }
}

.componentMobileNavList {
  padding: 0;
  list-style: none;
  font-weight: var(--font-weight-bold);
  color: var(--color-white);
  font-size: var(--font-size-22);
  position: relative;

  & > :not(:last-child) {
    margin-bottom: var(--size-24);
  }

  & > .relativeToParent {
    position: static;
  }
}

.componentMobileNavListItem {
  position: relative;

  & > .drawerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.mobileNavItem {
  color: var(--color-white);
  text-decoration: none;
}

.componentCloseButton {
  transition: transform var(--duration-sm);

  &:hover {
    transform: rotate(90deg);
  }
}

.drawerHeaderLayout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--size-header-height-sm) !important;

  @media (--viewport--md) {
    height: var(--size-header-height-lg) !important;
  }
}

.drawerContent {
  padding-top: var(--size-30);
  position: relative;
}

.componentMobileSubmenuList {
  list-style: none;
  padding-left: 0;

  & > :not(:last-child) {
    margin-bottom: var(--size-42);
  }
}

.componentMobileSubmenuListItem {
  color: var(--color-white);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-light);
}

.mobileSubmenuListItemLink {
  color: var(--color-white);
  text-decoration: none;
}

.componentDesktopNavItemItem {
  display: flex;
  align-items: center;
  color: var(--color-white);
  text-decoration: none;

  & > :nth-child(2) {
    margin-left: var(--size-6);
  }
}

.collapsible {
  display: flex;
  flex-direction: column;

  & > .submenuItems {
    margin-top: var(--size-12);
  }
}

.currentItem {
  display: flex;
  justify-content: space-between;
}

.componentCollapsible {
  overflow: hidden;
}

.mobileSubmenuList {
  list-style: none;
  padding-left: var(--size-24);

  & > * {
    margin-bottom: var(--size-8);
  }
}
